import React, {
  useState,
  useRef,
  Fragment,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  submitSpecCodeSelection,
  cleanSpecCode,
  setActiveVariant,
  setActiveEngine,
  getSpecCodeOptions,
  resetCheckboxes,
  fetchProductDownloads,
} from 'redux/actions';
import classNames from 'classnames';
import { isEmpty, find } from 'lodash/fp';
import { HEADERS, LEDHEADERS } from 'constants/spec-code-downloads';
import { COPY_TO_CLIPBOARD } from 'modules/copy-to-clipboard';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import { applySpecCodeStatements } from 'components/spec-code-new/apply-spec-code-statements';
import { getUniqueEngineTypes } from 'components/spec-code-and-downloads/functions';
import { SelectOption } from 'components/spec-code-new/select';
import { CodeContainer } from 'components/spec-code-new/code-container';
import { QuickSpec } from 'components/quick-spec';
import LEDTable from 'components/LEDTable';
import styles from 'components/spec-code-new/style.module.scss';
import specCodeContext from 'contexts/spec-code';
import AlertDialog from 'components/alert-dialog';

import {
  KEY_ENGINE_TYPES,
  KEY_FITTING_CODES,
  KEY_COLOUR_TEMPERATURE,
  KEY_BEZEL_COLOURS,
  KEY_OPTIONS,
  //KEY_BEAM_ANGLES,
  KEY_BAFFLE_COLOURS,
  KEY_DRIVERS,
  KEY_BEAM_ANGLES,
  //KEY_DRIVERS,
} from 'components/spec-code-new/constants';
import { createPortal } from 'react-dom';

export const SpecCodeNew = ({
  productOptions,
  disabledOptions,
  setDownloadsDisabled,
  smallScreen,
}) => {
  const dispatch = useDispatch();
  const { selected, optionsAvailable } = useSelector((state) => state.specCode);
  const product = useSelector((state) => state.product);
  const productName = useSelector((state) => state.product.data.productName);
  const productFamily = useSelector((state) => state.product.data.family);
  const productDownloads = useSelector((state) => state.productDownloads);
  const isBarrelRod = useSelector((state) => state.product.data.is_barrel_rod);
  const premiumBrassAvailability = useSelector(
    (state) => state.product.activeVariant.premium_brass_availability
  );
  const [codeComplete, setCodeComplete] = useState(false);
  const [copied, setCopied] = useState(false);
  const [clearCode, setClearCode] = useState(false);
  const uniqueEngineTypes = getUniqueEngineTypes(product.data);
  const generatedCode = useRef(null);
  //const optionsValues = getValueFromDataObject(selected[KEY_OPTIONS], 'value');
  const productsSSDefault = ['cue-100-lopro', 'roc-100', 'roc-100-lopro'];
  const { pageTemplate } = useSelector((state) => state.product.data);
  const headers = pageTemplate === 'led' ? LEDHEADERS : HEADERS;
  const stainlessSteelDefault =
    productsSSDefault.indexOf(product.data.slug) > -1;
  const specCodeRef = useContext(specCodeContext);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessages, setWarningMessages] = useState({
    title: '',
    button1: '',
    button2: '',
    message: '',
  });

  const openWarning = useCallback((open, title, button1, button2, message) => {
    if (!open) {
      setShowWarning(false);
    } else {
      setWarningMessages({
        title: title,
        button1: button1,
        button2: button2,
        message: message,
      });
      setShowWarning(true);
    }
  }, []);

  useEffect(() => {
    Object.assign(productOptions, {
      engine_types: productOptions['engine_type'],
    });
  }, [productOptions]);

  useEffect(() => {
    let output = {};

    Object.keys(productOptions).map((key) => {
      return (output[key] = [
        productOptions[key].map((obj, i) => {
          if (!disabledOptions[key] || isEmpty(disabledOptions[key])) {
            return { ...obj, disabled: false };
          }
          return {
            ...obj,
            disabled: find(obj, disabledOptions[key]) ? true : false,
          };
        }),
      ]);
    });

    let specCodeResult = applySpecCodeStatements(
      product.data.slug,
      selected,
      output,
      getValueFromDataObject(selected[KEY_OPTIONS], 'value'),
      {
        'alert-plated': {
          func: openWarning,

          args: {
            open: true,
            title: 'please review your selection',
            button1: 'change my selection',
            button2: 'proceed with my selection',
            message:
              'You have specified an IP65 rated product, however the finish you have selected is not suitable for outdoor use. For an outdoor finish, please choose from our powder paint or premium brass finishes. This does not affect products being used in bathrooms and other damp, indoor areas.',
          },
        },
        'alert-modifiedplated': {
          func: openWarning,

          args: {
            open: true,
            title: 'please review your selection',
            button1: 'change my selection',
            button2: 'proceed with my selection',
            message:
              'You have specified an IP65 rated product, however the finish you have selected is not suitable for outdoor use. For an outdoor finish, please choose from our anodised or premium brass finishes. This does not affect products being used in bathrooms and other damp, indoor areas.',
          },
        },
        'alert-premium': {
          func: openWarning,
          args: {
            open: true,
            title: 'please review your selection',
            button1: 'change my selection',
            button2: 'proceed with my selection',
            message:
              'Please note that our premium finishes are made from solid brass. As brass is a natural product, this finish will patina over time if used outdoors. Patina is the result of oxidation and does not affect a product’s function, although it may eventually alter the look of the finish.',
          },
        },
      }
    );

    const final = specCodeResult;
    Object.keys(specCodeResult).map((key) => {
      return (final[key] = [
        specCodeResult[key][0].map((obj, i) => {
          return {
            ...obj,
            disabled: find(obj, disabledOptions[key]) ? true : obj.disabled,
          };
        }),
      ]);
    });

    dispatch(getSpecCodeOptions(final));

    setCodeComplete(
      Object.values(selected).every(
        (e) => getValueFromDataObject(e, 'value').length
      )
    );
  }, [
    dispatch,
    productOptions,
    disabledOptions,
    selected,
    product.data.slug,
    openWarning,
  ]);
  /*
  useEffect(() => {
    const originalData = cloneDeep(optionsAvailable); // HELPER: clonedeep comes from lodash

    const specCodeResult = applySpecCodeStatements(
      product.data.slug,
      selected,
      originalData,
      optionsValues
    );

    dispatch(getSpecCodeOptions(specCodeResult));
    // eslint-disable-next-line
  }, [dispatch, selected, product.data.slug]);
*/
  useEffect(() => {
    return () => dispatch(cleanSpecCode());
  }, [dispatch]);

  useEffect(() => {
    const isOption67Selected =
      getValueFromDataObject(selected[KEY_OPTIONS], 'value').indexOf('67') > -1;

    if (!isEmpty(selected[KEY_OPTIONS])) {
      let updatedSelectedOptions;

      // Update selected.options based on the disabled values inside optionsAvailable
      optionsAvailable[KEY_OPTIONS][0].map((val) => {
        const stainlessSteel = optionsAvailable[KEY_BEZEL_COLOURS][0].find(
          (obj) => {
            return obj.value === 'ss';
          }
        );

        if (val.disabled) {
          if (
            selected[KEY_OPTIONS].find(
              (selectVal) => selectVal.value === val.value
            )
          ) {
            updatedSelectedOptions = selected[KEY_OPTIONS].filter(
              (selectVal) => {
                return selectVal.value !== val.value;
              }
            );

            return dispatch(
              submitSpecCodeSelection({
                [KEY_OPTIONS]: updatedSelectedOptions,
              })
            );
          }
        }

        // Update selected.bezel_colours based on the values inside optionsAvailable
        if (isOption67Selected && stainlessSteelDefault) {
          return dispatch(
            submitSpecCodeSelection({
              [KEY_BEZEL_COLOURS]: stainlessSteel,
            })
          );
        }
        if (pageTemplate === 'led') {
          return dispatch(
            submitSpecCodeSelection({
              [KEY_BEZEL_COLOURS]: { value: '', label: '', description: '' },
              [KEY_BAFFLE_COLOURS]: { value: '', label: '', description: '' },
              [KEY_DRIVERS]: { value: '', label: '', description: '' },
              [KEY_BEAM_ANGLES]: {
                value: 'h',
                label: 'wash',
                description: 'wash beam',
              },
            })
          );
        }
        return updatedSelectedOptions;
      });
    }
    // eslint-disable-next-line
  }, [selected[KEY_OPTIONS]]);

  // set the default values in the dropdowns
  const getSelectionValue = (header) => {
    if (header === KEY_ENGINE_TYPES) {
      return {
        value: product.activeEngine.engine_type.value,
        label: product.activeEngine.engine_type.label,
        description: product.activeEngine.engine_type.description,
      };
    }

    if (header === KEY_FITTING_CODES) {
      return {
        value: product.activeVariant.fitting_code.value,
        label: product.activeVariant.fitting_code.label,
      };
    }

    if (
      header !== KEY_FITTING_CODES &&
      header !== KEY_ENGINE_TYPES &&
      header !== KEY_OPTIONS
    ) {
      return {
        value: selected[header].value,
        label: selected[header].label,
        description: selected[header].description,
      };
    }

    if (header === KEY_OPTIONS) {
      if (pageTemplate === 'led') {
        return selected[header];
      }
      return [...selected[header]];
    }
  };

  // this useEffect sets the default values in the specCode state
  useEffect(() => {
    dispatch(
      submitSpecCodeSelection({
        [KEY_FITTING_CODES]: {
          value: product.activeVariant.fitting_code.value,
          label: product.activeVariant.fitting_code.label,
          description: product.activeVariant.fitting_code.description,
        },
      })
    );

    dispatch(
      submitSpecCodeSelection({
        [KEY_ENGINE_TYPES]: {
          value: product.activeEngine.engine_type.value,
          label: product.activeEngine.engine_type.label,
          description: product.activeEngine.engine_type.description,
        },
      })
    );
    // keep the default values after clearing
    if (clearCode) {
      getSelectionValue(KEY_ENGINE_TYPES);
      getSelectionValue(KEY_FITTING_CODES);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    product.activeEngine.engine_type,
    product.activeVariant.fitting_code,
    clearCode,
  ]);

  const handleChange = (selection, option) => {
    /* "connects" to the Select Variant component
     * and cleans all selections if any change happens
     */
    if (option === KEY_FITTING_CODES) {
      const newVariant = product.data.variants
        .filter(({ fitting_code }) => fitting_code.value === selection.value)
        .shift();
      if (newVariant) {
        setDownloadsDisabled(true);
        dispatch(cleanSpecCode());
        dispatch(setActiveVariant(newVariant));
      }
    }
    /** "connects" to the Engine Selector component (Choose your Engine)
     * and cleans all selections if any change happens
     */
    if (option === KEY_ENGINE_TYPES) {
      const newEngine = uniqueEngineTypes
        .filter(({ engine_type }) => engine_type.value === selection.value)
        .shift();
      if (newEngine) {
        setDownloadsDisabled(true);
        dispatch(cleanSpecCode());
        dispatch(setActiveEngine(newEngine));
      }
    }

    if (option === KEY_COLOUR_TEMPERATURE) {
      setDownloadsDisabled(true);
    }

    setCopied(false);
    setClearCode(false);
    dispatch(resetCheckboxes());

    if (Array.isArray(selection)) {
      dispatch(
        submitSpecCodeSelection({
          [option]: selection,
        })
      );
    } else {
      dispatch(
        submitSpecCodeSelection({
          [option]: {
            value: selection.value,
            label: selection.label,
            description: selection.description,
          },
        })
      );
    }
  };

  const handleAssetPack = () => {
    // Transform the selected
    const keys = Object.keys(selected);

    let downloadCodes = {};
    keys.forEach((key) => {
      if (key !== 'options' || pageTemplate === 'led') {
        downloadCodes[key] = selected[key].value;
      } else {
        downloadCodes[key] = selected[key].map((option) => {
          return option.value;
        });
      }
    });
    if (pageTemplate === 'led') {
      downloadCodes['product_name'] = productName;
    }
    setDownloadsDisabled(false);
    dispatch(fetchProductDownloads(downloadCodes));
  };

  const handleCopyToClipboard = () => {
    const text = generatedCode.current.innerText;
    COPY_TO_CLIPBOARD(text);
    setCopied(true);
  };

  const handleClearCode = () => {
    dispatch(cleanSpecCode());
    setDownloadsDisabled(true);
    setClearCode(true);
  };

  const btnClasses = classNames({
    [`${styles.btn}`]: true,
    [`${styles.ready}`]: codeComplete,
  });

  const wrapperBtnClasses = classNames({
    [`${styles['btn-wrapper']}`]: true,
    [`${styles.reverse}`]: codeComplete,
  });

  const clipboardBtnClasses = classNames({
    [`${styles['copy-btn']}`]: true,
    [`${styles.visible}`]: codeComplete,
  });

  const clearBtnClasses = classNames({
    [`${styles['copy-btn']}`]: true,
    [`${styles.visible}`]:
      getValueFromDataObject(selected[KEY_COLOUR_TEMPERATURE], 'value').length >
      0,
  });

  const specCodeTitle =
    pageTemplate === 'led' ? 'LED strip code builder' : 'specification code';
  const specCodeDesc =
    pageTemplate === 'led' ? (
      <p>
        Click the options below to create your bespoke LED strip specification
        code and then download your asset pack.
      </p>
    ) : (
      <p>
        Click the options below to create your bespoke specification code and
        then download your asset pack.
      </p>
    );

  return (
    <>
      {showWarning &&
        createPortal(
          <AlertDialog
            toggleFunc={setShowWarning}
            /*
            title="IP67 Warning"
            button1={'change my selection'}
            btn1callback={handleClearCode}
            button2={'proceed with my selection'}
            btn2callback={() => alert('hello')}
            */
            title={warningMessages.title}
            button1={warningMessages.button1}
            btn1callback={handleClearCode}
            button2={warningMessages.button2}
            btn2callback={() => alert('hello')}
          >
            {warningMessages.message}
          </AlertDialog>,
          document.body
        )}
      <section className={styles['spec-code']} ref={specCodeRef}>
        <h3>{specCodeTitle}</h3>
        <p>{specCodeDesc}</p>
        <div className={wrapperBtnClasses}>
          <button className={clearBtnClasses} onClick={() => handleClearCode()}>
            clear code
          </button>
          <button
            className={clipboardBtnClasses}
            onClick={() => handleCopyToClipboard()}
          >
            {copied ? 'copied ' : 'copy '}to clipboard
          </button>
        </div>
        <CodeContainer selected={selected} refToUse={generatedCode} />
        <div className={styles.wrapper}>
          {Object.keys(headers).map((option, index) => {
            const values = optionsAvailable[option];
            const options = values ? optionsAvailable[option][0] : [];
            const previousHeader = Object.keys(headers)[index - 1];
            const isDisabledColumn = !getValueFromDataObject(
              selected[previousHeader],
              'value'
            ).length;

            // If Baffle/Bezel

            if (option === 'bezel_colours' || option === 'baffle_colours') {
              const noBezelBaffleOptions = _.filter(options, (option) => {
                if (option.hasOwnProperty('bezel-type')) {
                  return option['bezel-type'] === '';
                } else {
                  return option['baffle-type'] === '';
                }
              });

              const powderPaintBezelBaffleOptions = _.filter(
                options,
                (option) => {
                  if (option.hasOwnProperty('bezel-type')) {
                    return option['bezel-type'] === 'powder-paint';
                  } else {
                    return option['baffle-type'] === 'powder-paint';
                  }
                }
              );

              const platedBezelBaffleOptions = _.filter(options, (option) => {
                if (option.hasOwnProperty('bezel-type')) {
                  return option['bezel-type'] === 'plated';
                } else {
                  return option['baffle-type'] === 'plated';
                }
              });

              const premiumBezelBaffleOptions = _.filter(options, (option) => {
                if (option.hasOwnProperty('bezel-type')) {
                  return option['bezel-type'] === 'premium';
                } else {
                  return option['baffle-type'] === 'premium';
                }
              });

              const ip67BezelBaffleOptions = _.filter(options, (option) => {
                if (option.hasOwnProperty('bezel-type')) {
                  return option['bezel-type'] === 'anodised';
                } else {
                  return option['baffle-type'] === 'anodised';
                }
              });

              let firstGroup;

              const optionsArray = [
                { grouping: 'noBezelBaffle', obj: noBezelBaffleOptions },
                {
                  grouping: 'powderBezelBaffle',
                  obj: powderPaintBezelBaffleOptions,
                },
                {
                  grouping: 'platedBezelBaffle',
                  obj: platedBezelBaffleOptions,
                },
                {
                  grouping: 'premiumBezelBaffle',
                  obj: premiumBezelBaffleOptions,
                },
                {
                  grouping: 'ip67BezelBaffle',
                  obj: ip67BezelBaffleOptions,
                },
              ];

              for (let i = 0; i < optionsArray.length; i++) {
                if (optionsArray[i].obj.length > 0) {
                  firstGroup = optionsArray[i].grouping;
                  break;
                }
              }

              return (
                <Fragment key={headers[option]}>
                  <div className={styles['spec-code-column']}>
                    {noBezelBaffleOptions.length > 0 && (
                      <>
                        <SelectOption
                          key={headers[option] + index}
                          header={
                            productFamily.find(
                              (element) => element === 'surface-track'
                            ) &&
                            headers[option] === 'bezel' &&
                            isBarrelRod
                              ? 'barrel & rod'
                              : headers[option]
                          }
                          options={noBezelBaffleOptions}
                          isMulti={
                            pageTemplate === 'led'
                              ? false
                              : headers[option] === KEY_OPTIONS
                          }
                          nonfirst={!(firstGroup === 'noBezelBaffle')}
                          value={getSelectionValue(option)}
                          isDisabled={
                            index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            isDisabledColumn
                          }
                          handleChange={(value) => handleChange(value, option)}
                          tooltips={{
                            bezel: product?.data?.tooltip_bezel,
                            baffle: product?.data?.tooltip_baffle,
                            'barrel & rod': product?.data?.tooltip_bezel,
                          }}
                          stainlessSteelDefault={stainlessSteelDefault}
                        />
                      </>
                    )}
                    {powderPaintBezelBaffleOptions.length > 0 && (
                      <>
                        <SelectOption
                          key={headers[option] + index}
                          header={
                            productFamily.find(
                              (element) => element === 'surface-track'
                            ) &&
                            headers[option] === 'bezel' &&
                            isBarrelRod
                              ? 'barrel & rod'
                              : 'powder paint'
                          }
                          ogheader={headers[option]}
                          nonfirst={!(firstGroup === 'powderBezelBaffle')}
                          options={powderPaintBezelBaffleOptions}
                          isMulti={
                            pageTemplate === 'led'
                              ? false
                              : headers[option] === KEY_OPTIONS
                          }
                          value={getSelectionValue(option)}
                          isDisabled={
                            index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            isDisabledColumn
                          }
                          handleChange={(value) => handleChange(value, option)}
                          tooltips={{
                            bezel: product?.data?.tooltip_bezel,
                            baffle: product?.data?.tooltip_baffle,
                            'barrel & rod': product?.data?.tooltip_bezel,
                          }}
                          stainlessSteelDefault={stainlessSteelDefault}
                        />
                      </>
                    )}
                    {platedBezelBaffleOptions.length > 0 && (
                      <>
                        <SelectOption
                          key={headers[option] + index}
                          header={
                            productFamily.find(
                              (element) => element === 'surface-track'
                            ) &&
                            headers[option] === 'bezel' &&
                            isBarrelRod
                              ? 'barrel & rod'
                              : 'plated'
                          }
                          ogheader={headers[option]}
                          nonfirst={!(firstGroup === 'platedBezelBaffle')}
                          options={platedBezelBaffleOptions}
                          isMulti={
                            pageTemplate === 'led'
                              ? false
                              : headers[option] === KEY_OPTIONS
                          }
                          value={getSelectionValue(option)}
                          isDisabled={
                            index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            isDisabledColumn
                          }
                          handleChange={(value) => handleChange(value, option)}
                          tooltips={{
                            bezel: product?.data?.tooltip_bezel,
                            baffle: product?.data?.tooltip_baffle,
                            'barrel & rod': product?.data?.tooltip_bezel,
                          }}
                          stainlessSteelDefault={stainlessSteelDefault}
                        />
                      </>
                    )}
                    {premiumBezelBaffleOptions.length > 0 && (
                      <>
                        <SelectOption
                          key={headers[option] + index}
                          header={
                            productFamily.find(
                              (element) => element === 'surface-track'
                            ) &&
                            headers[option] === 'bezel' &&
                            isBarrelRod
                              ? 'barrel & rod'
                              : 'premium'
                          }
                          premiumBrassAvailability={premiumBrassAvailability}
                          ogheader={headers[option]}
                          nonfirst={!(firstGroup === 'premiumBezelBaffle')}
                          options={premiumBezelBaffleOptions}
                          isMulti={
                            pageTemplate === 'led'
                              ? false
                              : headers[option] === KEY_OPTIONS
                          }
                          value={getSelectionValue(option)}
                          isDisabled={
                            index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            isDisabledColumn
                          }
                          handleChange={(value) => handleChange(value, option)}
                          tooltips={{
                            bezel: product?.data?.tooltip_bezel,
                            baffle: product?.data?.tooltip_baffle,
                            'barrel & rod': product?.data?.tooltip_bezel,
                          }}
                          stainlessSteelDefault={stainlessSteelDefault}
                        />
                      </>
                    )}
                    {ip67BezelBaffleOptions.length > 0 && (
                      <>
                        <SelectOption
                          key={headers[option] + index}
                          header={
                            productFamily.find(
                              (element) => element === 'surface-track'
                            ) &&
                            headers[option] === 'bezel' &&
                            isBarrelRod
                              ? 'barrel & rod'
                              : 'anodised'
                          }
                          ogheader={headers[option]}
                          nonfirst={!(firstGroup === 'ip67BezelBaffle')}
                          options={ip67BezelBaffleOptions}
                          isMulti={
                            pageTemplate === 'led'
                              ? false
                              : headers[option] === KEY_OPTIONS
                          }
                          value={getSelectionValue(option)}
                          isDisabled={
                            index !== 0 &&
                            index !== 1 &&
                            index !== 2 &&
                            isDisabledColumn
                          }
                          handleChange={(value) => handleChange(value, option)}
                          tooltips={{
                            bezel: product?.data?.tooltip_bezel,
                            baffle: product?.data?.tooltip_baffle,
                            'barrel & rod': product?.data?.tooltip_bezel,
                          }}
                          stainlessSteelDefault={stainlessSteelDefault}
                        />
                      </>
                    )}
                  </div>
                </Fragment>
              );
            } else {
              return (
                <Fragment key={headers[option]}>
                  <SelectOption
                    key={headers[option] + index}
                    header={
                      productFamily.find(
                        (element) => element === 'surface-track'
                      ) &&
                      headers[option] === 'bezel' &&
                      isBarrelRod
                        ? 'barrel & rod'
                        : headers[option]
                    }
                    options={options}
                    isMulti={
                      pageTemplate === 'led'
                        ? false
                        : headers[option] === KEY_OPTIONS
                    }
                    value={getSelectionValue(option)}
                    isDisabled={
                      index !== 0 &&
                      index !== 1 &&
                      index !== 2 &&
                      isDisabledColumn
                    }
                    handleChange={(value) => handleChange(value, option)}
                    tooltips={{
                      bezel: product?.data?.tooltip_bezel,
                      baffle: product?.data?.tooltip_baffle,
                      'barrel & rod': product?.data?.tooltip_bezel,
                    }}
                    stainlessSteelDefault={stainlessSteelDefault}
                  />
                </Fragment>
              );
            }
          })}
        </div>
        {pageTemplate === 'led' && <LEDTable />}
        <button
          className={btnClasses}
          onClick={() => {
            handleAssetPack();
          }}
        >
          {codeComplete
            ? productDownloads.loading
              ? 'generating your asset pack...'
              : 'create asset pack'
            : 'complete spec code for asset pack'}
        </button>
      </section>
      {codeComplete && (
        <QuickSpec
          data={selected}
          smallScreen={smallScreen}
          mainImage={product.data.thumbnail}
          productName={product.data.title}
          drawingOne={product.activeEngine.add_line_drawings[0]?.line_drawing}
          drawingTwo={product.activeEngine.add_line_drawings[1]?.line_drawing}
        />
      )}
    </>
  );
};
