import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getValueFromDataObject } from 'modules/get-value-from-data-object';
import { MagnifyingGlass } from 'components/icons';
import styles from './style.module.scss';
import { find, flatMap } from 'lodash';
import { Button } from 'components/buttons';
import { SIZE_MEDIUM } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';

export const Summary = ({
  mainImage,
  drawingOne,
  drawingTwo,
  data,
  handleQuicksheet,
  productName,
}) => {
  const summaryClasses = classNames({
    [`${styles.flex}`]: true,
    [`${styles.info}`]: true,
  });

  const drawingsClasses = classNames({
    [`${styles.drawings}`]: true,
    [`${styles['double-drawing']}`]: drawingOne && drawingTwo,
  });

  // Section to add degree number to the beam angle description
  const { activeEngine } = useSelector((state) => state.product);

  const rawBeamAngle = data.beam_angles.label;

  const words = rawBeamAngle ? rawBeamAngle.split(' ') : [];

  const selectedColourTemp = find(activeEngine.add_colour_temperature, (o) => {
    return o.colour_temperature === data.colour_temperature.label;
  });

  const beamAnglesFlat = flatMap(selectedColourTemp?.add_lit_effect, (o) => {
    return o.add_beam_angle;
  });

  const beamAngle = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');

  const { pageTemplate } = useSelector((state) => state.product.data);
  let codes = getValueFromDataObject(data, 'value');

  if (pageTemplate === 'led') {
    codes = codes.filter((code) => (code !== '') & (code !== 'h'));
  }

  const optiondescriptions = getValueFromDataObject(
    data.options,
    'description'
  );

  const selectedBeamAngle = find(beamAnglesFlat, (o) => {
    if (
      beamAngle === 'Ultra Spot' ||
      beamAngle === 'ultraspot' ||
      beamAngle === 'ultra spot' ||
      beamAngle === 'Ultra Spot'
    ) {
      return o.beam_angle === 'Ultraspot';
    } else {
      return o.beam_angle === beamAngle;
    }
  });

  const beamToDisplay =
    data.beam_angles.description +
    ', ' +
    selectedBeamAngle?.beam_width_fwhm +
    ', ' +
    selectedBeamAngle?.luminaire_lumens.replace('lm', ' luminaire lumens');

  const orderedDescriptions = [
    //data.fitting_codes.description,
    data.bezel_colours.description,
    data.baffle_colours.description,
    data.engine_type.description,
    data.colour_temperature.description,
    beamToDisplay,
    ...optiondescriptions,
    data.drivers.description,
    '2-step MacAdam ellipse',
  ];

  const descList = orderedDescriptions.map((description) => (
    <p key={description}>{description}</p>
  ));

  return (
    <div className={summaryClasses}>
      <div>
        <figure className={styles['main-image-container']}>
          {pageTemplate !== 'led' && (
            <ul>
              <li>Bezel: {data.bezel_colours.label}</li>
              <li>Baffle: {data.baffle_colours.label}</li>
            </ul>
          )}
          <img src={mainImage} alt="product highlight" />
        </figure>
      </div>
      <div className={drawingsClasses}>
        {drawingOne && (
          <a href={drawingOne} target="_blank" rel="noopener noreferrer">
            <figure>
              <img src={drawingOne} alt="technical drawing" />
            </figure>
            <MagnifyingGlass />
          </a>
        )}
        {drawingTwo && (
          <a href={drawingTwo} target="_blank" rel="noopener noreferrer">
            <figure>
              <img src={drawingTwo} alt="technical drawing" />
            </figure>
            <MagnifyingGlass />
          </a>
        )}
      </div>
      <div className={styles['spec-summary']}>
        <h3>{data.fitting_codes.description}</h3>
        <p className={styles['code-summary']}>
          {codes.join(' - ').replace(/,/g, '')}
        </p>
        <span className={styles.descriptions}>{descList}</span>

        <p>
          <br />
          fully repairable and reusable <br />
          >95% recycled, >95% recyclable
          <br />
          <br />
        </p>

        {false && (
          <address>
            Ripa Subhan / info@orluna.com / 00 44 (0) 1923 889712 / Orluna,
            Holly Industrial Park, Ryan Way, Watford, <br /> WD24 4YP United
            Kingdom
          </address>
        )}

        <span onClick={() => handleQuicksheet(productName + '-quicksheet')}>
          <Button
            size={SIZE_MEDIUM}
            theme={THEME_DARK}
            label={'Download Quicksheet'}
          />
        </span>
      </div>
    </div>
  );
};
